// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   //Start scope of LocalSystem url
    /*authURL: 'http://localhost:3000/gs-auth',
  adminUrl : 'http://localhost:3000/gs-admin',
  gsorder: 'http://localhost:3000/gs-orders',
  gsconsumer: 'http://localhost:3000/gs-consumer',
  gsworkorder: 'http://localhost:3000/gs-workorder'  */
//Start scope of LocalSystem url

  //Start scope of Staging server url

  //authURL: 'https://stg.api.globeship.com/gs-auth',
  //adminUrl: 'https://stg.api.globeship.com/gs-admin',
  //gsorder: 'https://stg.api.globeship.com/gs-orders',
 // gsconsumer: 'https://stg.api.globeship.com/gs-consumer',
 // gsworkorder: 'https://stg.api.globeship.com/gs-workorder'
  authURL: 'https://stage.api.globeship.ca/gs-auth',
  adminUrl: 'https://stage.api.globeship.ca/gs-admin',
  gsorder: 'https://stage.api.globeship.ca/gs-orders',
  gsconsumer: 'https://stage.api.globeship.ca/gs-consumer',
  gsworkorder: 'https://stage.api.globeship.ca/gs-workorder',
  gsinfo: 'https://stage.api.globeship.ca/gs-admin/v1'
  //gsinfo: 'https://stage.api.globeship.ca/gs-info'
   
  

  //End scope of Staging server url
};

// export const PROXY_CONFIG = {
//   "/api/proxy": {
//       "target": "http://44.234.133.144:8081",
//       "secure": false,
//       "bypass": function (req, res, proxyOptions) {
//           if (req.headers.accept.indexOf("html") !== -1) {
//               console.log("Skipping proxy for browser request.");
//               return "/index.html";
//           }
//           req.headers["X-Custom-Header"] = "yes";
//       }
//   }
// }


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
